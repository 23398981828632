import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import styles from "./login.module.scss"

class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    let authUser = null

    if (typeof sessionStorage !== "undefined") {
      authUser = sessionStorage.getItem('graphiql:sessid') || null
    }

    this.state = {
      authUser: authUser,
      password: '',
      error: '',
      loading: false,
    }

  }

  checkAuth = () => {
    if (!!this.state.authUser) {
      navigate('/');
    }
  }

  componentDidMount() {
    this.checkAuth()
  }

  componentDidUpdate() {
    // this.checkAuth()
  }

  doLogin = event => {
    const datoCMSAPItoken = this.props.data.site.siteMetadata.fullAPIToken

    this.setState({loading: true})
    this.setState({error: ''})

    fetch(
      'https://graphql.datocms.com/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${datoCMSAPItoken}`,
        },
        body: JSON.stringify({
          query: '{ jelszavak(filter: {jelszo: {eq: "' + this.state.password + '"}}){ id belepesekSzama }}'
        }),
      }
    )
    .then(res => res.json())
    .then((res) => {
      if (res.data.jelszavak && res.data.jelszavak.id) {
        localStorage.setItem('graphiql:sessid', res.data.jelszavak.id)
        sessionStorage.setItem('graphiql:sessid', res.data.jelszavak.id)
        navigate(window.location.search.replace('?redirectTo=', '') || '/')

        // track and count password usage
        this.props.data.site.siteMetadata.productionMode && fetch(
          'https://site-api.datocms.com/items/' + res.data.jelszavak.id,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${datoCMSAPItoken}`,
            },
            body: JSON.stringify({
              "data": {
                "type": "item",
                "id": res.data.jelszavak.id,
                "attributes": {
                  "belepesek_szama": res.data.jelszavak.belepesekSzama ? parseInt(res.data.jelszavak.belepesekSzama) + 1 : 1
                }
              }
            }),
          }
        )
      } else {
        this.setState({error: 'A megadott jelszó nem megfelelő.'})
      }
      this.setState({loading: false})
    })
    .catch((error) => {
      console.log(error);
    });

    event.preventDefault()
  }

  render() {
    const defaultMetadata = this.props.data.datoCmsSite.globalSeo
    const pageTitle = 'Bejelentkezés' + defaultMetadata.titleSuffix
    const pageDescription = defaultMetadata.fallbackSeo.description
    const {password, error, loading} = this.state

    return (
      <Layout location={this.props.location}  readOnlyAPIToken={this.props.data.site.siteMetadata.readOnlyAPIToken} >

        <Helmet
          htmlAttributes={{ lang: 'hu' }}
          meta={[{ name: 'description', content: pageDescription }]}
          title={pageTitle}
        />


        <form className={styles.loginForm} onSubmit={this.doLogin}>
          <p className={styles.subtitle}>Az oldalra való belépéshez</p>
          <h1 className={styles.title}>Kérjük add meg egyedi azonosító kódod*</h1>

          <div className={styles.formRow}>
            <input type="password"
              value={password}
              name="password"
              className={error ? styles.hasError :''}
              onChange={event =>
                this.setState({ [event.target.name]: event.target.value })
            }/>
            {
              error && <p className={styles.errorNotification}>{error}</p>
            }
            {
              loading && <p className={styles.notification}>Jelszó ellenőrzése...</p>
            }
          </div>

          <div className={styles.formRow}>
            <button type="submit" >Belépek</button>
          </div>

          <p className={styles.hint}>
            * Üsd be a tőlünk kapott kódot és jó szórakozást.<br/>Nem kaptál kódot? Ennek több oka is lehet. Például az, hogy még nem találkoztunk. Ha ezen mindenképp változtatnunk kell, küldj egy emailt ide: <a href="mailto:mate@stem.men">mate@stem.men</a>
          </p>

        </form>


      </Layout>
    )
  }
}

export default LoginPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        productionMode
        fullAPIToken
        readOnlyAPIToken
      }
    }
    datoCmsSite {
      globalSeo {
        siteName
        titleSuffix
        fallbackSeo {
          title
          description
        }
      }
    }
  }
`
